<template>
  <div>
    <div class="topButton">
      <div class="titleFont noButtonTitle floatLeft">建立用戶資料</div>
    </div>
    <div class="clearBoth"></div>

    <CForm>
      <template>
        <CCard>
          <CCardHeader>用戶資訊</CCardHeader>
          <CCardBody>
            <ValidationObserver tag="form" ref="form">
              <CForm>
                <div role="group" class="col-sm-8 form-group">
                  <label for="uid-drab8n61hjm" class=""> 所屬公司 </label>
                  <select
                    id="uid-6vvdf2f2vvr"
                    class="form-control"
                    v-model="company"
                  >
                    <option
                      :data-key="value.id"
                      :value="value.id"
                      v-for="(value, key) in companies"
                      :key="key"
                    >
                      {{ value.name }}
                    </option>
                  </select>
                </div>
                <ValidationProvider
                  rules="required"
                  v-slot="{ errors }"
                  name="帳號"
                >
                  <div role="group" class="col-sm-8 form-group">
                    <label for="uid-drab8n61hjm" class=""> 帳號 </label>
                    <input
                      id="uid-drab8n61hjm"
                      type="text"
                      placeholder=""
                      class="form-control name"
                    />
                  </div>
                  <div class="errMsg">
                    <span>{{ errors[0] }}</span>
                  </div>
                </ValidationProvider>
                <ValidationProvider
                  rules="min:12"
                  v-slot="{ errors }"
                  :bails="false"
                  tag="div"
                  name="password"
                  mode="lazy"
                >
                  <div role="group" class="col-sm-8 form-group">
                    <label for="uid-drab8n61hjm" class=""> 密碼 </label>
                    <input
                      id="uid-drab8n61hjm"
                      type="password"
                      placeholder=""
                      class="form-control password"
                      v-model="password"
                    />
                  </div>
                  <div
                    class="errMsg"
                    v-for="(error, index) in errors"
                    :key="index"
                  >
                    <span>{{ errors[0] }}</span>
                  </div>
                </ValidationProvider>
                <ValidationProvider
                  rules="confirmPassword:@password"
                  v-slot="{ errors }"
                  :bails="false"
                  tag="div"
                  mode="lazy"
                  name="repeat password"
                >
                  <div role="group" class="col-sm-8 form-group">
                    <label for="uid-drab8n61hjm" class=""> 確認密碼 </label>
                    <input
                      id="uid-drab8n61hjm"
                      type="password"
                      placeholder=""
                      class="form-control confirmpassword"
                      v-model="confirmPassword"
                    />
                  </div>
                  <div
                    class="errMsg"
                    v-for="(error, index) in errors"
                    :key="index"
                  >
                    <span>{{ errors[0] }}</span>
                  </div>
                </ValidationProvider>
                <div role="group" class="col-sm-8 form-group">
                  <label for="uid-drab8n61hjm" class=""> 暱稱 </label>
                  <input
                    id="uid-drab8n61hjm"
                    type="text"
                    placeholder=""
                    class="form-control nickname"
                  />
                </div>
                <div class="form-check">狀態</div>
                <div class="radioBox">
                  <div class="form-check form-check-inline">
                    <input
                      class="form-check-input"
                      type="radio"
                      name="status"
                      id="1"
                      value="1"
                      v-model="status"
                    />
                    <label class="form-check-label" for="64">啟用</label>
                  </div>
                  <div
                    class="form-check form-check-inline"
                    style="margin-bottom:5px;"
                  >
                    <input
                      class="form-check-input"
                      type="radio"
                      name="status"
                      id="0"
                      value="0"
                      v-model="status"
                    />
                    <label class="form-check-label" for="32">停用</label>
                  </div>
                </div>
                <div
                  role="group"
                  class="col-sm-8 form-group"
                  disabled="disabled"
                >
                  <label for="uid-6n2djcu4dqm" class=""> 角色 </label>
                  <select
                    id="uid-2yvkqocxfk3"
                    class="form-control role"
                    v-model="roleType"
                  >
                    <option value="0">請選擇</option>
                    <option
                      :data-key="value.id"
                      :value="value.id"
                      v-for="(value, key) in role"
                      :key="key"
                    >
                      {{ value.name }}
                    </option>
                  </select>
                </div>
                <div role="group" class="col-sm-8 form-group">
                  <label for="uid-drab8n61hjm" class=""> 備註 </label>
                  <textarea
                    id="uid-tqu3z9rc3lt"
                    class="form-control remark"
                  ></textarea>
                </div>
              </CForm>
            </ValidationObserver>
          </CCardBody>
          <div class="form-group form-actions positionR">
            <div class="sendButton">
              <div class="button floatRight"></div>
              <div class="button floatRight">
                <CButton block color="dark" variant="outline" @click="sendBtn()"
                  >送出
                </CButton>
              </div>

              <div class="clearBoth"></div>
            </div>
          </div>
        </CCard>
      </template>
    </CForm>

    <!-- 共用錯誤彈窗 -->
    <Modal></Modal>
  </div>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex'
import Modal from '../Modal.vue'
import regeneratorRuntime from '../../utils/runtime.js'
export default {
  name: 'EditAccount',
  data() {
    return {
      id: this.$route.params.id,
      companyID: this.$route.params.companyID,
      company: '',
      password: '',
      confirmPasswor: '',
      nickname: '',
      status: 1,
      roleType: 0,
      remark: '',
      visible: false,
      detailCompany: '',
      companyCheck: '',
      options: null,
      postOptions: null,
      confirmPassword: '',
      visibleCompany: [
        { ba: 'ba-20220521-01', company: 'CompanyA', parent: 'ECV' },
        { ba: 'ba-202200456-02', company: 'CompanyB', parent: 'ECV' },
        { ba: 'ba-20220952-01', company: '', parent: 'CompanyA' },
      ],
      role: [
        { id: 1, name: '角色A' },
        { id: 2, name: '角色B' },
      ],
      companies: [
        { id: 1, name: 'companyA' },
        { id: 2, name: 'companyB' },
        { id: 3, name: 'companyC' },
      ],
    }
  },
  components: {
    Modal,
  },
  computed: {
    ...mapGetters([
      'get_user',
      'get_userCompany',
      'get_visibleCompany',
      'get_companies',
    ]),
    fields() {
      return [
        {
          key: 'ba',
          label: 'BA',
        },
        {
          key: 'company',
          label: '所屬公司',
        },

        {
          key: 'parent',
          label: '上層公司',
        },

        {
          key: 'edit',
          label: '',
          _style: 'width:10%',
          sorter: false,
          filter: false,
        },
      ]
    },
  },
  methods: {
    sendBtn() {
      this.$refs.form.validate().then((valid) => {
        if (valid) {
          let data = {
            id: this.$route.params.id,
            password: $('.password').val(),
            nickname: $('.nickname').val(),
            status: $('input[name=radio]:checked').val(),
            remark: $('.remark').val(),
          }
          //   console.log('data', data)
          this.$store.dispatch('actionUsersUpdate', data)
        } else {
          console.log('NO')
        }
      })
    },
    send() {
      console.log('關聯', this.postOptions.join(','))
      let data = {
        id: this.id,
        company_ids: this.postOptions.join(','),
      }

      this.$store.dispatch('actionSetVisibleCompany', data)
    },
    closeVisible() {
      this.visible = false
      //   this.options = false
      //   this.postOptions = false
    },
    async addVisible() {
      let data = {
        parent_id: this.companyID,
        page: 1,
        per_page: 10,
      }
      console.log('data', data)
      await this.$store.dispatch('actionCompanies', data)
      //   console.log(this.get_companies)
      this.options = this.get_companies
      var testOptionsRender = ''
      this.postOptions = testOptionsRender.split(',')

      this.visible = true
    },
  },
  mounted() {},
  created() {},
}
</script>
